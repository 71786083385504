
import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';

const routeGuardPatient = async (to, from, next) => {
    next();
};

@Component
export default class Patient extends Vue {
  public beforeRouteEnter(to, from, next) {
    routeGuardPatient(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardPatient(to, from, next);
  }
}
